import request from "../../api";
import { endpoints } from "api/endpoints";

export const getAllPlanData = async () => {
    try {
        return await request.get(endpoints.getPlan)
    }
    catch (error) {
        return false;
    }
};

export const getPlanDetails = async (id) => {
    try {
        return await request.get(`${endpoints.getPlan}${id}/`)
    }
    catch (error) {
        return false;
    }
};

export const planDetailsUpdate = async (updatedData) => {
    try {
        return await request.post(endpoints.getPlan, updatedData)
    } catch (error) {
        return false;
    }
};

export const addPlan = async (newparams) => {
    try {
        return await request.post(endpoints.addplans, newparams)
    } catch (error) {
        return false;
    }
};