import request from "../../api";
import { endpoints } from "api/endpoints";

export const usersData = async () => {
    try {
        return await request.get(endpoints.getAllUser)
    } catch (error) {
        return false;
    }
};

export const usersDetails = async (id) => {
    try {
        return await request.get(endpoints.getUserDetails + `${id}` + '/')
    } catch (error) {
        return false;
    }
};


export const userDetailsUpdate = async (id, value) => {
    try {
        return await request.patch(endpoints.getUserDetails + `${id}` + '/', value)
    } catch (error) {
        return false;
    }
}

