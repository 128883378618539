import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Label, Button } from "reactstrap";
import { transactionData } from './api';
import { transactionDataUpdate } from './api';


function TransactionDetails() {

    const [transactionList, setTransactionList] = useState([])
    const [showData, setShowData] = useState(false)

    const location = useLocation();
    let id = location.pathname.slice(33)

    useEffect(() => {
        getData(id);
    }, []);

    const getData = async (id) => {
        const response = await transactionData(id)
        if (response) {
            setTransactionList(response.data)
        }
    }
    console.log(transactionList, "transactionList")

    const handleChange = (e) => {
        const { name, value } = e.target
        setTransactionList({ ...transactionList, [name]: value })
    }

    const handleSubmit = async () => {
        const response = await transactionDataUpdate(id, transactionList)
        if (response) {
            setTransactionList(response.data)
        }
        setShowData(false);
    }

    const handleEdit = async () => {
        setShowData(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className='d-flex justify-content-between align-items-center mb-3'>
                    <h4>Transaction</h4>
                    <div className='d-flex'>
                        {
                            showData ?
                                <>
                                    <Button type="button" color="primary" onClick={handleSubmit}>Submit</Button>
                                </>
                                :
                                <>
                                    <Button type="button" color="primary" onClick={handleEdit}>Edit</Button>
                                </>
                        }
                    </div>
                </Container>
                <div className="row">
                    <div className="col-lg-6">
                        <Card>
                            <CardBody className='business-details'>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Order Id
                                        </Label>
                                    </div>
                                    {
                                        showData ?
                                            <>
                                                <div className="businessdata-input">
                                                    <input className='form-control form-control-sm' type="text" name='order_id' value={transactionList.order_id} onChange={handleChange} />
                                                </div>
                                            </> :
                                            <>
                                                <Label>{transactionList.order_id}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Payment Id</Label>
                                    </div>
                                    {
                                        showData ?
                                            <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='payment_id' value={transactionList.payment_id} onChange={handleChange} />
                                                </div>
                                            </> :
                                            <>
                                                <Label>{transactionList.payment_id}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Business Id
                                        </Label>
                                    </div>
                                    {
                                        showData ?
                                            <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='business_id' value={transactionList.business_id} onChange={handleChange} />
                                                </div>
                                            </> :
                                            <>
                                                <Label>{transactionList.business_id}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Transaction Status
                                        </Label>
                                    </div>
                                    {
                                        showData ?
                                            <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='transaction_status' value={transactionList.transaction_status} onChange={handleChange} />
                                                </div>
                                            </> :
                                            <>
                                                <Label>{transactionList.transaction_status}</Label>
                                            </>
                                    }
                                </div>
                                <div className='d-flex mt-4'>
                                    <div className="businessdata-label">
                                        <Label>
                                            Plan
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="text" name='plan' value={transactionList.plan} onChange={handleChange} />
                                            </div>
                                        </> :
                                            <>
                                                <Label>{transactionList.plan}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            User
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="text" name='user' value={transactionList.user} onChange={handleChange} />
                                            </div>
                                        </> :
                                            <>
                                                <Label>{transactionList.user}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Transfer amount
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="text" name="transfer_amount" value={transactionList.transfer_amount} onChange={handleChange} />
                                            </div>
                                        </> :
                                            <>
                                                <Label>{transactionList.transfer_amount}</Label>
                                            </>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TransactionDetails