import { FETCH_PAYMENT, FETCH_GRAPHDATA } from "./actionType";

export const Deshboard1 = (state = [], action) => {
    switch (action.type) {
        case FETCH_PAYMENT:
            return [...state, ...action.payload];
        default:
            return state;
    }
}




export const graphData = (state = [], action) => {
    switch (action.type) {
        case FETCH_GRAPHDATA:
            return [...state, ...action.payload];
        default:
            return state;
    }
}








