import request from "api";
import { endpoints } from "api/endpoints";

export const businessCategories = async () => {
    try {
        return await request.get(endpoints.getBusinessCategory);
    } catch (error) {
        return false;
    }
};

export const businessCategoryDetails = async (id) => {
    try {
        return await request.get(endpoints.getBusinessCategoryDetails + id + '/');
    } catch (error) {
        return false;
    }
};

// export const businessCategoryUpdate = async (id, formdata) => {
//     try {
//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", `Token ${localStorage.getItem("Token")}`);

//         var requestOptions = {
//             method: 'PATCH',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };
//         return fetch("https://dev.brandemanager.com/api/v1/custom_panel/business/business-category" + id + "/", requestOptions)
//     } catch (error) {
//         return false;
//     }
// };

export const businessCategoryUpdate = async (id, finalData) => {
    try {
        return await request.patch(endpoints.getBusinessCategoryDetails + id + '/', finalData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    } catch (error) {
        return false;
    }
}

export const addBusinessCategory = async (finalData) => {
    try {
        return await request.post(endpoints.addBusinessCategory, finalData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    } catch (error) {
        return false;
    }
};