import React, { useState } from "react";
import { Button, ModalBody, ModalFooter } from "reactstrap";

function CommentModal(props) {
    const [message, setmessage] = useState(props.modalDiscription[0].disc);

    const handleComment = event => {
        setmessage(event.target.value);
        // console.log(message, "hello")
    }

    return (
        <>
            <div className="conf-modal-container">
                <ModalBody>
                    <p className="text-center">Add a Comment</p>
                    <textarea rows="7" value={message} onChange={e => handleComment(e)}>
                    </textarea>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button type="button" color="primary" onClick={() => props.setComment(props.modalDiscription[0].id, message)}>
                        Save
                    </Button>
                    <Button type="button" color="secondary" onClick={props.closeModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </div>
            <div className="modal-bg-overlay"></div>
        </>
    )
}
export default CommentModal;
