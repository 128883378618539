import request from "api";
import { endpoints } from "api/endpoints";

export const categories = async () => {
    try {
        return await request.get(endpoints.getCategories);
    } catch (error) {
        return false;
    }
};

export const categoriesDetails = async (id) => {
    try {
        return await request.get(endpoints.getCategories + id + '/');
    } catch (error) {
        return false;
    }
};

export const categoriesDetailsUpdate = async (id, categoryList) => {
    try {
        return await request.patch(endpoints.getCategories + id + '/', categoryList, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    } catch (error) {
        return false;
    }
};

export const addCategory = async (finalData) => {
    try {
        return await request.post(endpoints.getCategories, finalData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    } catch (error) {
        return false;
    }
};
