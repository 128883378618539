import request from "api"
import { endpoints } from "api/endpoints"
import { FETCH_PAYMENT } from "./actionType"
import { FETCH_GRAPHDATA } from "./actionType"


export const fetchPayment = () => async (dispatch) => {
    const response = await request.get(endpoints.getpayment, {
        headers: { Authorization: `Token ${localStorage.getItem("Token")}` }
    })
    console.log("response", response);
    dispatch({ type: FETCH_PAYMENT, payload: response.data.payload })
}


export const graphData = () => async (dispatch) => {
    const response = await request.get(endpoints.getUserData, {
        headers: { Authorization: `Token ${localStorage.getItem("Token")}` }
    })
    console.log("graphdata", response.data.payload.Users)
    dispatch({ type: FETCH_GRAPHDATA, payload: response.data.payload.Users })
}


