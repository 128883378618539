import request from "../../api";
import { endpoints } from "api/endpoints";

export const getbusinessData = async () => {
    try {
        return await request.get(endpoints.getBusinessData)
    } catch (error) {
        return false;
    }
};

export const getbusinessDetails = async (id) => {
    try {
        return await request.get(endpoints.getBusinessDetails + id + '/')
    } catch (error) {
        return false;
    }
};

export const businessDetailsUpdate = async (id, businessData) => {
    try {
        return await request.patch(endpoints.getBusinessDetails + id + '/', businessData)
    } catch (error) {
        return false;
    }
};

export const addBusinessDetails = async (updatedData) => {
    try {
        return await request.post(endpoints.getBusinessDetails, updatedData)
    } catch (error) {
        return false;
    }
}
