import React, { Component, useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Button } from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom"
import { businessCategories } from "./api";
import { DayCellContent } from "@fullcalendar/core";
import moment from 'moment'


function BusinessCategory() {
    const [businessCategoryList, setBusinessCategoryList] = useState([]);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        const response = await businessCategories();
        if (response) {
            setBusinessCategoryList(response.data);
            console.log(response, "response")
        }
    };
    console.log(businessCategoryList, "businessCategoryList")

    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);

    const columns = [
        {
            dataField: "category_name",
            text: "Category Name",
            sort: true,
        },
        {
            dataField: "image",
            text: "Image",
            sort: true,
            formatter: (DayCellContent, businessCategoryList) => (
                <>
                    <img src={businessCategoryList.image} style={{ width: "50px" }}></img>
                </>
            )
        },
        {
            dataField: "is_active",
            text: "Is active",
            sort: true,
            formatter: (is_active) => (
                <>
                    {is_active ? (
                        <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
                    ) : (
                        <i className="mdi mdi-close-circle text-danger font-size-20"></i>
                    )
                    }
                </>
            )
        },
        {
            dataField: "",
            text: "Edit",
            sort: true,
            formatter: (DayCellContent, businessCategoryList) => (
                <>
                    <Link to={"/business_category/business-category-details/" + businessCategoryList.id} className="text-dark">
                        <i className="dripicons-document-edit" />
                    </Link>
                </>
            )
        }

    ]

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        // totalSize: businessCategoryList.length,
        custom: true,
    }
    const { SearchBar } = Search;

    document.title = "Business Category | Brand eManager";

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h4>Business Category</h4>
                    </Container>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={columns}
                                        data={businessCategoryList}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={businessCategoryList}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="8">
                                                                <div className="d-flex justify-content-end">
                                                                    <Link to="/business_category/business-category-details">
                                                                        <Button type="button" color="primary" className="d-flex justify-content-end align-items-center">
                                                                            <i className='bx bx-plus me-2 '></i>Add Business Category
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        columns={columns}
                                                                        // selectRow={selectRow}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-bordered customize-table"
                                                                        }
                                                                        headerWrapperClasses={"table-dark"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </React.Fragment>
        </>
    );
}

export default BusinessCategory;
