import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//New Users
import NewUsers from "../pages/NewUsers/index"

//Renew Status
import RenewStatus from "../pages/RenewStatus/index"

//Admin Pannel
import AdminPannel from "../pages/AdminPannel/index"

//Users List
import Users from "../pages/Users/index"
import UserData from "../pages/Users/UserData"

//Business
import Business from "pages/Business/index"
import BusinessDetails from "pages/Business/BusinessDetails"

//Plan
import Plan from "pages/Plan"
import PlanDetails from "pages/Plan/PlanDetails"

//BUsiness Category
import BusinessCategory from "pages/BusinessCategory"
import BusinessCategoryDetails from "pages/BusinessCategory/BusinessCategoryDetails"

//Category
import Category from "pages/Category"
import CategoryDetails from "pages/Category/CategoryDetails"

//Category Images
import CategoryImages from "pages/CategoryImages"
import CategoryImg from "pages/CategoryImages/CategoryImg"

//Transaction
import Transaction from "pages/Transaction"
import TransactionDetails from "pages/Transaction/TransactionDetails"
import { categoriesDetails } from "pages/Category/api"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // New users
  { path: "/new_users", component: NewUsers },

  //Renew Status
  { path: "/renew_status", component: RenewStatus },

  //Admin Pannel
  { path: "/admin_pannel", component: AdminPannel },

  //Users List
  { path: "/users", component: Users },
  { path: "/users/users-details/:id", component: UserData },

  //Business
  { path: "/business", component: Business },
  { path: "/business/business-details/:id", component: BusinessDetails },
  { path: "/business/business-details", component: BusinessDetails },

  //Plan
  { path: "/plan", component: Plan },
  { path: "/plan/plan-details/:id", component: PlanDetails },
  { path: "/plan/plan-details", component: PlanDetails },

  //Business Category
  { path: "/business_category", component: BusinessCategory },
  { path: "/business_category/business-category-details/:id", component: BusinessCategoryDetails },
  { path: "/business_category/business-category-details", component: BusinessCategoryDetails },

  //Category
  { path: "/category", component: Category },
  { path: "/category/category-details/:id", component: CategoryDetails },
  { path: "/category/category-details", component: CategoryDetails },

  //Category Images
  { path: "/category_images", component: CategoryImages },
  { path: "/category_images/categoryimg/:id", component: CategoryImg },
  { path: "/category_images/categoryimg", component: CategoryImg },

  //Transaction
  { path: "/transaction", component: Transaction },
  { path: "/transaction/transaction-details/:id", component: TransactionDetails },


  //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes }
