import axios from "axios";
import { urls } from "../config/index";

const request = axios.create({
  baseURL: urls.apiBaseUrl + "/",
  // headers: {
  //   Authorization: "fa7288c47ab2e48f1a3187099944e1c764510c06",
  // },
});

// Add a request interceptor
request.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // config.headers.authorization = `Token ${localStorage.getItem("Token")}`;
    // config.headers.authorization = `Token 9b53d004aa31da022134ec9da998e5d85b111ec7`;


    var data = localStorage.getItem("authUser");

    if (data !== null) {

      const user = JSON.parse(data)

      var Token = user.data.payload.token;


      config.headers.Authorization =
        "Token " + Token;

      console.log("request", config);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("response", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("error", error);
    return Promise.reject(error);
  }
);

export default request;
