import request from "api";
import { endpoints } from "api/endpoints";

export const categoriesImage = async () => {
    try {
        return await request.get(endpoints.getCategoryImage);
    } catch (error) {
        return false;
    }
};

export const categoriesImageDetail = async (id) => {
    try {
        return await request.get(`${endpoints.getCategoryImage}${id}/`);
    } catch (error) {
        return false;
    }
};

export const categoryImages = async (id, finalData) => {
    try {
        return await request.patch(endpoints.getCategoryImage + id + '/', finalData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    } catch (error) {
        return false;
    }
};

export const addCategoryImages = async (finalData) => {
    try {
        return await request.post(endpoints.getCategoryImage, finalData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    } catch (error) {
        return false;
    }
};