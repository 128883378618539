import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const newUserData = async () => {
  try {
    return await request.get(endpoints.newUserData);
  } catch (error) {
    return false;
  }
};

export const updateFrameStatus = async (id, params) => {
  return request.put(`${endpoints.newUserData}${id}/`, params);
};
