export const endpoints = {
  postLogin: "user/login/",
  newUserData: "plan/",
  renewFollowup: "plan/user-followup/",
  getdata: "plan/followup/",
  getCommentData: "plan/follow-up/",
  assignUserData: "plan/user-followup/",
  userList: "user/list/",
  assignUser: "plan/user-followup/",
  getpayment: "payment-summary/",
  getUserData: "table-data/",
  getAllUser: "custom_panel/user/user_detail/",
  getBusinessData: "custom_panel/business/user-businesses/",
  getUserDetails: "custom_panel/user/user_detail/",
  getBusinessDetails: "custom_panel/business/user-businesses/",
  getPlan: "custom_panel/plan/plan-detail/",
  addplans: "custom_panel/plan/create-plan/",
  // getPlanName: "custom_panel/plan/plan-name/",
  getBusinessCategory: "custom_panel/business/business-category",
  getBusinessCategoryDetails: "custom_panel/business/business-category",
  addBusinessCategory: "/custom_panel/business/business-category/",
  getCategories: "custom_panel/home/category/",
  getCategoryImage: "custom_panel/home/category-image/",
  getTransaction: "custom_panel/payment/transaction/"
};
