import React, { useState } from 'react'
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Button } from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useEffect } from 'react';
// import { usersData } from './api';
import { Link } from "react-router-dom"
import { getAllPlanData } from './api';


function Plan() {

    const [planData, setPlanData] = useState([])

    const columns = [
        {
            dataField: "plan.plan_id",
            text: "Plan ID",
            sort: true,
        },
        {
            dataField: "plan.name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "plan.month_count",
            text: "Month Count",
            sort: true,
        },
        {
            dataField: "plan.is_default",
            text: "Is Default",
            sort: true,
            formatter: (is_default) => (
                <>
                    {is_default ? (
                        <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
                    ) : (
                        <i className="mdi mdi-close-circle text-danger font-size-20"></i>
                    )
                    }
                </>
            )
        },
        {
            dataField: "plan.is_premium",
            text: "Is Premium",
            sort: true,
            formatter: (is_premium) => (
                <>
                    {is_premium ? (
                        <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
                    ) : (
                        <i className="mdi mdi-close-circle text-danger font-size-20"></i>
                    )
                    }
                </>
            )
        },
        {
            dataField: "plan.day_count",
            text: "Day Count",
            sort: true,
        },
        {
            dataField: "plan.daily_image_count",
            text: "Daily Image Count",
            sort: true,
        },
        {
            dataField: "plan.description",
            text: "Description",
            sort: true,
        },
        {
            dataField: "Edit",
            text: "Edit",
            sort: true,
            formatter: (DayCellContent, planData) => (
                <>
                    <Link to={"/plan/plan-details/" + planData.plan.plan_id} className="text-dark">
                        <i className="dripicons-document-edit" />
                    </Link>
                </>
            )
        }
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: planData.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;

    const getData = async () => {
        const response = await getAllPlanData();
        if (response) {
            setPlanData(response.data);
        }
    };
    console.log(planData, "planData")


    useEffect(() => {
        getData();
    }, []);

    document.title = "Plan | Brand eManager";

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h4>Plan</h4>
                    </Container>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columns} data={planData}>
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={planData}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="8">
                                                                <div className="d-flex justify-content-end">
                                                                    <Link to="/plan/plan-details">
                                                                        <Button type="button" color="primary" className="d-flex justify-content-end align-items-center"><i className='bx bx-plus me-2 '></i>Add Plan</Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        // selectRow={selectRow}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-bordered customize-table"
                                                                        }
                                                                        headerWrapperClasses={"table-dark"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <button className="add-btn">
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </button> */}
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </>
    )
}

export default Plan