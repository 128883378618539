import React, { Component, useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Container, } from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { transaction } from "./api";
import { DayCellContent } from "@fullcalendar/core";
import { planFollowupList } from "pages/RenewStatus/apis";
import moment from 'moment'
import { Link } from "react-router-dom";


function Transaction() {
    const [transactionData, setTransactionData] = useState([])

    useEffect(() => {
        getTransaction();
    }, []);


    const getTransaction = async () => {
        const response = await transaction();
        if (response) {
            setTransactionData(response.data);
        }
    }
    console.log(transactionData, "transactionData")

    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);

    const columns = [
        {
            dataField: "transaction_id",
            text: "Transaction ID",
            sort: true,
        },
        {
            dataField: "order_id",
            text: "Order ID",
            sort: true,
        },
        {
            dataField: "payment_id",
            text: "Payment ID",
            sort: true,
        },
        {
            dataField: "business_id",
            text: "Business ID",
            sort: true,
        },
        {
            dataField: "user",
            text: "User",
            sort: true,
        },
        {
            dataField: "plan",
            text: "Plan",
            sort: true,
        },
        {
            dataField: "transaction_status",
            text: "Transaction Status",
            sort: true,
        },
        {
            dataField: "transfer_amount",
            text: "Transfer Amount",
            sort: true,
        },
        {
            dataField: "created_at",
            text: "Created At",
            sort: true,
            formatter: (date) => (
                <tr>
                    {
                        date !== null ?
                            moment(Date(date)).format('DD-MM-YYYY HH:MM') : ""
                    }
                </tr>
            )
        }, {
            dataField: "Edit",
            text: "Edit",
            sort: true,
            formatter: (DayCellContent, transactionData) => (
                <>
                    <Link to={"/transaction/transaction-details/" + transactionData.transaction_id} className="text-dark">
                        <i className="dripicons-document-edit" />
                    </Link>
                </>
            )
        }
    ]

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: transactionData.length,
        custom: true,
    }
    const { SearchBar } = Search;

    document.title = "Renew Status | Brand eManager";

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h4>Transaction</h4>
                    </Container>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={columns}
                                        data={transactionData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={transactionData}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        columns={columns}
                                                                        // selectRow={selectRow}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-bordered customize-table"
                                                                        }
                                                                        headerWrapperClasses={"table-dark"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </>
    );
}

export default Transaction;
