import React, { useState, useEffect } from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { planFollowupList } from './apis';
import moment from 'moment'

function CommentListModal({ commentListData, closeModal }, props) {
    console.log(commentListData, "list data")
    const [planFollowup, setPlanFollowup] = useState([]);


    useEffect(() => {
        const id = commentListData.id
        getPlanFollowupList(id);
    }, []);

    const getPlanFollowupList = async (id) => {
        const response = await planFollowupList(id);
        if (response) {
            setPlanFollowup(response.data.payload);
            console.log(response, "planFollowup")
            console.log(planFollowup, "okok")
        }
    }

    return (
        <>
            <div className="conf-modal-container">
                <div className='commentlist-data p-3'>
                    <p className='business-name'>{commentListData.name}</p>
                    <p>{commentListData.bId}</p>
                </div>
                <ModalBody className='conf-modal-wrap'>
                    <div>
                        <ul className="list-group list-group-flush">
                            {planFollowup.length ? <>
                                <p className='font-size-14 mb-0'><b>Comments</b></p>
                                {
                                    planFollowup.reverse().map((data, index) => {


                                        const date = moment(data.created_at).format('DD-MM-YYYY HH:mm')

                                        // const date = data.created_at.slice(0, 10);
                                        // const time = data.created_at.slice(11, 16);
                                        return (
                                            <>
                                                <li className="list-group-item px-0 pt-3 pb-2" key={index}>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <p className="text-muted font-size-14 mb-0">
                                                                {data.comment}
                                                            </p>
                                                            <h5 className="font-size-14 mb-1">
                                                                {data.is_user_intrested ?
                                                                    <span className="bg-success badge me-2">Intrested</span> :
                                                                    <span className="bg-danger badge me-2">Not Intrested</span>}
                                                                <small className="text-muted float-end">
                                                                    {date}
                                                                </small>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })
                                } </> :
                                <>
                                    <h2 className='my-5 text-center text-muted' style={{ opacity: 0.1 }}><b>No Comments Available</b></h2>
                                </>}
                        </ul>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button type="button" color="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </div>
            <div className="modal-bg-overlay"></div>
        </>
    )
}

export default CommentListModal;