import React, { Component, useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
} from "reactstrap";
import PropTypes from "prop-types";
import { newUserData, updateFrameStatus } from "./Apis";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import DeleteModal from "components/Common/DeleteModal";
import ConfirmationModal from "components/Common/ConfirmationModal";
import { statusClasses } from "common/data/tasks";
import { DayCellContent } from "@fullcalendar/core";
import CommentModal from "./CommentModal"




function NewUsers() {
  const [newUserList, setNewUserList] = useState([]);
  const [commentParams, setCommentParams] = useState([]);
  const [isEdit, setIsEdit] = useState();
  const [editComment, setEditComment] = useState([]);
  const [frameAddConfModal, setFrameAddConfModal] = useState(false);
  const [modalApiData, setModalApiData] = useState([]);
  const [confTitle, setConfTitle] = useState(false);
  const [userApprovedConfModal, setUserApprovedConfModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [modalDiscription, setModalDiscription] = useState();


  const getNewUserData = async () => {
    // modalApiData
    const response = await newUserData();
    if (response) {
      setNewUserList(response.data.payload);
    }
    // console.log(response, "hii")
  };

  useEffect(() => {
    getNewUserData();
  }, []);



  const handleFrameStatus = (id, status) => {
    setModalApiData([{ id: id, status: status }]);
    setConfTitle(!status)
    setFrameAddConfModal(true)
  };

  const cancelModal = () => {
    setFrameAddConfModal(false)
  }

  const handleFrameStatusAPI = () => {
    const id = modalApiData[0].id;
    const status = modalApiData[0].status;
    const frameStatus = !status;
    let params = {}
    if (frameStatus == true) {
      params = {
        is_frame_added: frameStatus,
      };
    }
    else {
      params = {
        is_frame_added: frameStatus,
        has_user_approved: frameStatus
      };
    }

    handleChanges(id, params);
    setFrameAddConfModal(false)
  }

  // const handleApproveStatus = (id, status) => {
  //   const approvelStatus = !status;
  //   const params = {
  //     has_user_approved: approvelStatus,
  //   };
  //   handleChanges(id, params);
  // };

  const handleApproveStatus = (id, status) => {
    // setFrameAddConfModal(true)
    setModalApiData([{ id: id, status: status }]);
    setConfTitle(!status)
    setUserApprovedConfModal(true)
  }

  const handleApproveStatusAPI = () => {
    const id = modalApiData[0].id;
    const status = modalApiData[0].status;
    const approvelStatus = !status;
    const params = {
      has_user_approved: approvelStatus
    };
    handleChanges(id, params)
    setUserApprovedConfModal(false)
  }
  const cancelApprovedModal = () => {
    setUserApprovedConfModal(false)
  }

  // const handleEdit = id => {
  //   setIsEdit(id)
  // };

  // const handleCommentChanges = (e, i) => {
  //   const newData = [...newUserList];
  //   const newValue = (newData.find(e => e.business_id === i).discription = e);
  //   setNewUserList(newData);
  // };

  // const updateComment = (i) => {
  //   const updatedComment = newUserList.find(e => e.business_id === i).discription
  //   const params = { discription: updatedComment, };
  //   handleChanges(i, params);
  //   setIsEdit(undefined);
  // };

  const handleChanges = async (id, params) => {
    console.log(id, "id selected");
    const response = await updateFrameStatus(id, params);
    if (response) {
      getNewUserData();
    } else {
    }
  };

  const cancelCommentModal = () => {
    setCommentModal(false)
  }
  const handleCommentstatus = (id, disc) => {
    setModalDiscription([{ id: id, disc: disc }])
    console.log(id)
    setCommentModal(true)
  }

  const setComment = (id, message) => {
    const params = {
      discription: message
    }
    handleChanges(id, params);
    setCommentModal(false)
  }

  //new

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);


  //console.log(user.business_id, "id")
  const columns = [
    {
      dataField: "user",
      text: "User",
      sort: true,
    },
    {
      dataField: "business_name",
      text: "Business Name",
      sort: true,
    },
    {
      dataField: "business_id",
      text: "Business Id",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Contact No",
      sort: true,
    },
    {
      dataField: "is_frame_added",
      text: "Is frame Added?",
      sort: true,
      formatter: (DayCellContent, user) => (
        <label className="switch">
          <input type="checkbox" className="form-check-input" role="switch" id={user.business_id} checked={user.is_frame_added}
            onClick={e => handleFrameStatus(user.business_id, user.is_frame_added)} />
          <span className="slider round"></span>
        </label>
      ),
    },
    {
      dataField: "has_user_approved",
      text: "Has user approved?",
      sort: true,
      formatter: (DayCellContent, user) => (
        <label className="switch">
          <input type="checkbox" className="form-check-input" role="switch" id={user.business_id}
            checked={user.is_frame_added ? user.has_user_approved : false}
            onClick={e => handleApproveStatus(user.business_id, user.has_user_approved)}
            disabled={!user.is_frame_added} />
          <span className="slider round"></span>
        </label>
      ),
    },
    {
      dataField: "discription",
      text: "Comments",
      sort: "false",
      formatter: (DayCellContent, user) => (
        <>
          {user.discription}
        </>
      )
    },
    {
      dataField: "Edit",
      text: "Edit",
      sort: "false",
      formatter: (DayCellContent, user) => (
        <>
          <button className="edit-btn" onClick={e => handleCommentstatus(user.business_id, user.discription)}>
            <i className="bx bx-edit"></i>
          </button>
        </>
      )
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: newUserList.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // };

  const { SearchBar } = Search;

  //meta title
  document.title = "New Users | Brand eManager";


  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <h4>New Users</h4>
          </Container>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columns} data={newUserList}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={newUserList}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-bordered customize-table"
                                    }
                                    headerWrapperClasses={"table-dark"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>



                  {/* <div className="table-responsive">
                    <Table
                      className="table mb-0 table-striped table-bordered customize-table"
                      defaultSorted={defaultSorted}
                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#User</th>
                          <th>#Business Name</th>
                          <th>#Business Id</th>
                          <th>#Contact No.</th>
                          <th>#Is frame Added?</th>
                          <th>#Has user approved?</th>
                          <th colSpan={2} className="comment-section">
                            #Comments
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {newUserList.map(user => {
                          return (
                            <tr key={user.business_id}>
                              <td>{user.user}</td>
                              <td>{user.business_name}</td>
                              <td>{user.business_id}</td>
                              <td>{user.contact}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" className="form-check-input" role="switch" id={user.business_id} checked={user.is_frame_added}
                                    onClick={e => handleFrameStatus(user.business_id, user.is_frame_added)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" className="form-check-input" role="switch" id={user.business_id}
                                    // checked={user.has_user_approved}
                                    checked={
                                      user.is_frame_added
                                        ? user.has_user_approved
                                        : false
                                    }
                                    onClick={e => handleApproveStatus(user.business_id, user.has_user_approved)}
                                    disabled={!user.is_frame_added}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                {isEdit == user.business_id ? (
                                  <>
                                    <textarea rows={3} id={user.business_id} value={user.discription} onChange={e => {
                                      handleCommentChanges(e.target.value, user.business_id);
                                    }}></textarea>
                                    <div>
                                      <button className="btn btn-success py-1" onClick={() => updateComment(user.business_id)}>
                                        Save
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>{user.discription}</>
                                )}
                              </td>
                              <td className="table-btn">
                                <button className="edit-btn" onClick={() => handleEdit(user.business_id)}>
                                  <i className="bx bx-edit"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {frameAddConfModal && (
            <ConfirmationModal
              handleApi={handleFrameStatusAPI}
              text={`${confTitle ? "frame is added" : "frame is not added"}`}
              closeModal={cancelModal}
            />
          )}
          {userApprovedConfModal && (
            <ConfirmationModal
              handleApi={handleApproveStatusAPI}
              text={`${confTitle ? "User has approved?" : "user has not approved"
                }`}
              closeModal={cancelApprovedModal}
            />
          )}
          {commentModal && (
            <CommentModal
              closeModal={cancelCommentModal}
              setComment={setComment}
              modalDiscription={modalDiscription}
            />
          )}
        </div>
      </React.Fragment>
    </>
  );
}

export default NewUsers;

