import request from "api";
import { endpoints } from "api/endpoints";

export const transaction = async () => {
    try {
        return await request.get(endpoints.getTransaction);
    } catch (error) {
        return false;
    }
};

export const transactionData = async (id) => {
    try {
        return await request.get(endpoints.getTransaction + id + '/');
    } catch (error) {
        return false;
    }
}

export const transactionDataUpdate = async (id, transactionList) => {
    try {
        return await request.patch(endpoints.getTransaction + id + '/', transactionList);
    } catch (error) {
        return false;
    }
}


