import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

function WelcomeCard() {
    return (
        <>
            <React.Fragment>
                <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                        <Row>
                            <Col xs="7">
                                <div className="text-primary p-3">
                                    <h6 className="text-primary">Total Bussiness</h6>
                                    <h4 className="text-primary">9846541545</h4>
                                </div>
                            </Col>
                            <Col xs="5" className="align-self-end">
                                <img src={profileImg} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                    <CardBody className="pt-0">
                        <Row>
                            <Col sm="12">
                                <div className="pt-4">
                                    <Row>
                                        <Col xs="4">
                                            <h5 className="font-size-15"><i className="far fa-calendar-alt"></i> 125</h5>
                                            <p className="text-muted mb-0">Today</p>
                                        </Col>
                                        <Col xs="4">
                                            <h5 className="font-size-15"><i className="far fa-calendar-alt"></i> 1245</h5>
                                            <p className="text-muted mb-0">This Week</p>
                                        </Col>
                                        <Col xs="4">
                                            <h5 className="font-size-15"><i className="far fa-calendar-alt"></i> 1245</h5>
                                            <p className="text-muted mb-0">This Month</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        </>
    )
}

export default WelcomeCard