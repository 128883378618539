import moment from 'moment/moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Label, Button } from "reactstrap";
// import { categories } from './api';
import { categoriesDetails } from './api';
import { categoriesDetailsUpdate } from './api';
import { addCategory } from './api';


function CategoryDetails() {

    const intialState = { name: '', image: '', is_festival: '', is_permanent: '', business_category: '', notification_title: '', notification_body: '' }
    const [categoryList, setCategoryList] = useState([])
    const [showData, setShowData] = useState(false)
    const [checked, setChecked] = useState(false)
    const [error, setError] = useState(false)
    const [finalData, setFinalData] = useState({})

    const loaction = useLocation();
    let id = loaction.pathname.slice(27);

    useEffect(() => {
        if (id) {
            getData(id);
        }
        else {
            setCategoryList(intialState);
        }
    }, []);

    const getData = async (id) => {
        const response = await categoriesDetails(id);
        if (response) {
            setCategoryList(response.data)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setCategoryList({ ...categoryList, [name]: value })
        setFinalData({ ...finalData, [name]: value })
    }

    const handledate = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target
        var date = moment(value).format('YYYY-MM-DDT00:00')
        console.log(date,)
        setFinalData({ ...finalData, [name]: date })
        console.log({ ...finalData, [name]: date }, "finaldata")
    }


    const handleClick = (e) => {
        const { name, checked } = e.target
        setCategoryList({ ...categoryList, [name]: checked })
        setFinalData({ ...finalData, [name]: checked })
    }

    const handleClickFestival = (e) => {
        var status = e.target.checked
        const { name, checked } = e.target
        setCategoryList({ ...categoryList, [name]: checked })
        setFinalData({ ...finalData, [name]: checked })
        setChecked(status)
    }

    const handleChangeImage = (e) => {
        const { name, files } = e.target
        if (e.target.files) {
            setCategoryList({ ...categoryList, [name]: files[0] })
            setFinalData({ ...finalData, [name]: files[0] })
        }
    }

    const handleSubmit = async () => {
        // if (checked === true) {
        //     if (date) {
        //         categoriesDetailsUpdated()
        //         setError(false)
        //     }
        //     else {
        //         setError(true)
        //     }
        // }
        // else {

        // }
        // if (checked === true)
        //     categoriesDetailsUpdated()
        if (id) {
            if (checked === true) {
                if (finalData.date === undefined) {
                    setError(true);
                }
                else {
                    categoriesDetailsUpdated()
                    setError(false);
                }
            }
            else {
                categoriesDetailsUpdated()
            }
        }
        else {
            const response = await addCategory(finalData);
            if (response) {
                setShowData(false);
            }
        }
    }

    const categoriesDetailsUpdated = async () => {
        const response = await categoriesDetailsUpdate(id, finalData);
        if (response) {
            setCategoryList(response.data)
        }
        console.log(finalData, "finalData")
        setShowData(false);
    }

    const handleEdit = () => {
        setShowData(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className='d-flex justify-content-between align-items-center mb-3'>
                    <h4>Category Details</h4>
                    <div className='d-flex'>
                        {
                            showData ?
                                <>
                                    <Button type="button" color="primary" onClick={handleSubmit}>Submit</Button>
                                </>
                                : <>
                                    <Button type="button" color="primary" onClick={handleEdit} className="d-flex justify-content-between align-items-center"><i className='bx bx-edit me-2 '></i>Edit</Button>
                                </>
                        }
                    </div>
                </Container>
                <div className="row">
                    <div className="col-lg-6">
                        <Card>
                            <CardBody className='business-details'>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Name
                                        </Label>
                                    </div>
                                    {
                                        showData ?
                                            <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='name' value={categoryList.name} onChange={handleChange} />
                                                </div>
                                            </> :
                                            <>
                                                <Label>{categoryList.name}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Image</Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {!showData && <img src={categoryList.image} style={{ width: "50px" }} ></img>}
                                        {showData && <input className="form-control form-control-sm" type='file' name='image' onChange={handleChangeImage}></input>}
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is festival
                                        </Label>
                                    </div>

                                    {
                                        showData ? <>
                                            <div className="userdata-input">
                                                <label className="switch small-switch">
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_festival' checked={categoryList.is_festival ? true : false} onClick={handleClickFestival} />
                                                    <span className="slider round slim"></span>
                                                </label>
                                            </div>
                                        </> :
                                            <>
                                                <Label>{categoryList.is_festival ? "Yes" : "No"}</Label>
                                            </>
                                    }
                                </div>
                                {
                                    categoryList.is_festival ?
                                        <>
                                            <div className='d-flex mt-4'>
                                                <div className="businessdata-label">
                                                    <Label>
                                                        Date
                                                    </Label>
                                                </div>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" id="element" type="date" name='date' value={moment(categoryList.date).format('YYYY-MM-DD')} onChange={handledate} />
                                                    {
                                                        error && <>

                                                            <h3 style={{ color: "red" }}>select date</h3>

                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </> : <>

                                            {/* {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="date" name='notification_title' value={categoryList.date} onChange={handleChange} />
                                            </div>
                                        </> :
                                            <>
                                                <Label>{categoryList.date}</Label>
                                            </>
                                    } */}
                                        </>
                                }
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is Permanent
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="userdata-input">
                                                <label className="switch small-switch">
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_permanent' checked={categoryList.is_permanent ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span>
                                                </label>
                                            </div></> :
                                            <>
                                                <Label>{categoryList.is_permanent ? "Yes" : "No"}</Label>
                                            </>
                                    }
                                </div>

                                <div className='d-flex mt-4'>
                                    <div className="businessdata-label">
                                        <Label>
                                            Notification title
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="text" name='notification_title' value={categoryList.notification_title} onChange={handleChange} />
                                            </div>
                                        </> :
                                            <>
                                                <Label>{categoryList.notification_title}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Notofication body
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="text" name='notification_body' value={categoryList.notification_body}
                                                    onChange={handleChange} />
                                            </div>
                                        </> :
                                            <>
                                                <Label>{categoryList.notification_body}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Description
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="businessdata-input">
                                                <input className="form-control form-control-sm" type="text" name='description' value={categoryList.description} onChange={handleChange} />
                                            </div></> :
                                            <>
                                                <Label>{categoryList.description}</Label>
                                            </>
                                    }
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is reviewed
                                        </Label>
                                    </div>
                                    {
                                        showData ? <>
                                            <div className="userdata-input">
                                                <label className="switch small-switch">
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_reviewed' checked={categoryList.is_reviewed ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span>
                                                </label>
                                            </div>
                                        </> :
                                            <>
                                                <Label>{categoryList.is_reviewed ? "Yes" : "No"}</Label>
                                            </>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default CategoryDetails