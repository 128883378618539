import React from 'react'
import { useState } from 'react';
import { PhotoshopPicker } from 'react-color';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { addComments } from './apis';


function RenewStatusModal(props) {
    const [addComment, setAddComments] = useState()
    const [userIntrested, setUserIntrested] = useState()
    const [error, setError] = useState(false)
    const [isLoding, setIsLoading] = useState(false)

    const handleSend = (e) => {
        setError(false)
        setAddComments(e.target.value)

    }
    const handleClick = (e) => {
        setUserIntrested(!userIntrested)
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        if (addComment === undefined) {
            setError(true)
            setIsLoading(false)
        } else {
            const params = {
                plan_renewal_id: props.addCommentid,
                comment: addComment,
                is_user_intrested: userIntrested
            }
            console.log(params)
            const response = await addComments(params);

            if (response) {
                setIsLoading(false)
                props.closeModal()
                // console.log("ok")
            }
        }
    }
    // console.log(error)
    return (

        <>
            <div className="conf-modal-container">
                <ModalBody>
                    <p className="text-center">Add a Comment</p>
                    <textarea rows="7" name='name' onChange={handleSend} value={addComment}>
                    </textarea>

                    <div className='d-flex align-items-center mt-2'>
                        <p className='font-size-14 me-3 mb-0'>Is User Intrested?</p>
                        <label className="switch">
                            <input type="checkbox" className="form-check-input" role="switch" onClick={handleClick} checked={userIntrested} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </ModalBody>

                {/* {error ? <>
                    <div className='error-message text-center'>
                        please enter comment
                    </div>
                </> : <></>} */}
                <ModalFooter className="justify-content-center">

                    {isLoding ? <>
                        loading...
                    </> : <><Button type="button" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button></>}


                    <Button type="button" color="secondary" onClick={props.closeModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </div>
            <div className="modal-bg-overlay"></div>
        </>
    )

}

export default RenewStatusModal;