import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Button,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";

import { Link, withRouter, useHistory } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import axios from "axios";
import * as Yup from "yup";
import { postLogin } from "./apis";
import { useDispatch } from "react-redux";
import { LOGIN_USER } from "store/auth/login/actionTypes";

function Login() {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [failToast, setFailToast] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const formSubmit = async () => {
    const user = {
      phone: userName,
      password: password,
    };
    console.log(user);
    dispatch({ type: LOGIN_USER, payload: { user, history } });
    const response = await postLogin(user);
    localStorage.setItem("Token", response.data.payload.token)
  };

  const handleSubmin = e => {
    e.preventDefault();
    formSubmit();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }


  console.log(showPassword)

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to Brand eManager.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required("Please Enter Your Email"),
                        password: Yup.string().required(
                          "Please Enter Valid Password"
                        ),
                      })}
                    >
                      <Form className="form-horizontal" onSubmit={handleSubmin}>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Phone number
                          </Label>
                          <Field
                            name="email"
                            type="text"
                            className="form-control"
                            onChange={e => setUserName(e.target.value)}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="password" className="form-label">
                            Password
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Field
                              name="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="true"
                              className="form-control"
                              onChange={e => setPassword(e.target.value)}
                            />
                            <button
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                              onClick={handleShowPassword}
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Forgot your
                            password?
                          </Link>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&apos;t have an account ? &nbsp;
                  <Link to="register" className="fw-medium text-primary">
                    Signup Now
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} Brand eManager. Crafted with
                  <i className="mdi mdi-heart text-danger" /> by Srashtasoft
                </p>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <div>
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={successToast} className="bg-success">
                      <ToastBody className="text-white">
                        Successfully Login!
                      </ToastBody>
                    </Toast>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <div>
                  <div
                    className="position-fixed top-0 end-0 p-3"
                    style={{ zIndex: "1005" }}
                  >
                    <Toast isOpen={failToast} className="bg-danger">
                      <ToastBody className="text-white">
                        Invalid Credentials!
                      </ToastBody>
                    </Toast>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Login;
