import React, { Component, useState, useEffect } from "react";
import { Table, Row, Col, Card, CardBody, CardTitle, Container, } from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { followpList } from "./apis";
import RenewStatusModal from "./RenewStatusModal";
import CommentListModal from "./CommentListModal";
import { DayCellContent } from "@fullcalendar/core";
import { planFollowupList } from "./apis";
import moment from 'moment'


function RenewStatus() {
  const [folloupList, setFolloupList] = useState([]);
  const [renewStatusModal, setRenewStatusModal] = useState(false);
  const [commentListModal, setCommentListModal] = useState(false);
  const [commentListData, setCommentListData] = useState()
  const [addCommentid, setAddCommentID] = useState("")


  useEffect(() => {
    getFollowupList();
    // getPlanFollowupList();
  }, []);



  const getFollowupList = async () => {
    const response = await followpList();
    if (response) {
      setFolloupList(response.data.payload);
      console.log(response, "followupList")
    }
  };

  const handleComment = (id) => {
    setRenewStatusModal(true)
    setAddCommentID(id)
  }

  const cancelCommentModal = () => {
    setRenewStatusModal(false)
    // alert("");
  }

  const handleCommentList = (id, bId, name) => {
    setCommentListModal(true)
    setCommentListData({ id: id, bId: bId, name: name })
    console.log("hello", id, name)
  }


  const cancelCommentListModal = () => {
    setCommentListModal(false)
  }

  // const data = folloupList.map(person => {
  //   const name = person.comment
  //   const job = planFollowup.find(job => job.id == person.id);
  //   return { ...person, title: job. }
  // });

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const columns = [
    {
      dataField: "business_id",
      text: "Business Id",
      sort: true,
    },
    {
      dataField: "business_name",
      text: "Business Name",
      sort: true,
    },
    {
      dataField: "contact",
      text: "contact",
      sort: true,
    },
    {
      dataField: "assigned_user",
      text: "Assigned User",
      sort: true,
    },
    {
      dataField: "is_plan_renewed",
      text: "Purchased / Renewed",
      sort: true,
      formatter: (user) => (
        <tr>
          {user.is_new_business ? (
            <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
          ) : (
            <i className="mdi mdi-close-circle text-danger font-size-20"></i>
          )}
        </tr>
      )
    },
    {
      dataField: "followup_date",
      text: "Followup date",
      sort: true,
      formatter: (date) => (
        <tr>
          {
            date !== null ?
              moment(Date(date)).format('DD-MM-YYYY HH:MM') : ""
          }
        </tr>
      )
    },
    {
      dataField: "is_deleted",
      text: "Is deleted ?",
      sort: true,
      formatter: (user) => (
        <tr>
          {
            user.is_deleted ? (
              <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
            ) : (
              <i className="mdi mdi-close-circle text-danger font-size-20"></i>
            )
          }
        </tr>
      )
    },
    {
      dataField: "is_new_business",
      text: "Is new business?",
      sort: true,
      formatter: (DayCellContent, user) => (
        <tr>
          {user.is_new_business ? (
            <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
          ) : (
            <i className="mdi mdi-close-circle text-danger font-size-20"></i>
          )}
        </tr>
      )
    },
    {
      dataField: "discription",
      text: "Comments",
      sort: true,
      formatter: (DayCellContent, user) => (
        <>
          <button className="view-btn" onClick={() => handleCommentList(user.id, user.business_id, user.business_name)} >
            <i className="fa fa-eye" aria-hidden="true"></i>
          </button>
        </>
      )
    },
    {
      dataField: "Edit",
      text: "Add comment",
      sort: true,
      formatter: (DayCellContent, user) => (
        <>
          <button className="add-btn" onClick={() => handleComment(user.id)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </>
      )
    }
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: folloupList.length,
    custom: true,
  }
  const { SearchBar } = Search;

  document.title = "Renew Status | Brand eManager";

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <h4>Renew Status</h4>
          </Container>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={folloupList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={folloupList}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    columns={columns}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-bordered customize-table"
                                    }
                                    headerWrapperClasses={"table-dark"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>



                  {/* <div className="table-responsive">
                    <Table className="table mb-0 table-striped table-bordered customize-table">
                      <thead className="table-dark">
                        <tr>
                          <th>#Id</th>
                          <th>#Business Name</th>
                          <th>#Business Id</th>
                          <th>#Contact No.</th>
                          <th>#Assigned User</th>
                          <th>#Is New Business</th>
                          <th>#Is Plan Renewed</th>
                          <th>#Is Deleted</th>
                          <th>#Followup Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {folloupList.map(user => {
                          return (
                            <tr key={user.business_id}>
                              <td>{user.id}</td>
                              <td>{user.business_name}</td>
                              <td>{user.business_id}</td>
                              <td>{user.contact}</td>
                              <td>{user.assigned_user}</td>
                              <td>
                                {user.is_new_business ? (
                                  <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
                                ) : (
                                  <i className="mdi mdi-close-circle text-danger font-size-20"></i>
                                )}
                              </td>
                              <td>
                                {user.is_plan_renewed ? (
                                  <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
                                ) : (
                                  <i className="mdi mdi-close-circle text-danger font-size-20"></i>
                                )}
                              </td>
                              <td>
                                {user.is_deleted ? (
                                  <i className="mdi mdi-checkbox-marked-circle text-success font-size-20"></i>
                                ) : (
                                  <i className="mdi mdi-close-circle text-danger font-size-20"></i>
                                )}
                              </td>
                              <td>{user.followup_date}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {renewStatusModal && (
            <RenewStatusModal
              closeModal={cancelCommentModal}
              addCommentid={addCommentid}
            />
          )}
          {commentListModal && (
            <CommentListModal
              closeModal={cancelCommentListModal}
              commentListData={commentListData}
            />
          )}
        </div>
      </React.Fragment>
    </>
  );
}

export default RenewStatus;
