import React, { Component, useState, useEffect } from "react";
import { Button, Table, Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { followpList } from "pages/RenewStatus/apis";
import { userList } from "./api";
import { assignUserFollowup } from "./api";
import { set } from "lodash";



function RenewStatus() {
  const [folloupList, setFolloupList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [bussinessData, setbussinessData] = useState([])
  const [selectedUser, setSelectedUser] = useState("none");
  const [error, setError] = useState();
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    getFollowupList();
    // getPlanFollowupList();
    getUserList();
  }, []);


  const getFollowupList = async () => {
    const response = await followpList();
    if (response) {
      setFolloupList(response.data.payload);
      console.log(response, "followupList")
    }
  };

  const onChangeDropDown = (data) => {
    setSelectedUser(data);
    setValidation(false)
    console.log(data, "data")
  };


  const getUserList = async () => {
    const response = await userList();
    if (response) {
      setUserData(response.data.payload);
      console.log(userData, "userdata")
    }
  }


  const handleSubmit = async () => {
    if (bussinessData.length == 0) {
      setError(true)
      setValidation(false)
    }
    else if (selectedUser === "none") {
      setValidation(true)
      setError(false)
    }
    else {
      setError(false)
      setValidation(false)
      const params = {
        business_id: bussinessData,
        assigned_user: selectedUser,
      }
      console.log(bussinessData, "busineessData")
      const response = await assignUserFollowup(params);
      if (response) {
        console.log("final data")
        getFollowupList();
      }
    }
  }


  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const columns = [
    {
      dataField: "business_id",
      text: "Business Id",
      sort: true,
    },
    {
      dataField: "business_name",
      text: "Business Name",
      sort: true,
    },
    {
      dataField: "assigned_user",
      text: "Assigned User",
      sort: true,
    },
    {
      dataField: "assigned_user_firstname",
      text: "Assigned User",
      sort: true,
      // formatter: (user) => (

      //   user.assigned_user_firstname + user.assigned_user_lastname
      // )
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  let selectedItems = [];

  const selectRow = {
    mode: "checkbox",
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        for (let row of rows) {
          setbussinessData(bussinessData => [...bussinessData, row.id]);
        }
        setError(false)
      }
      else {
        setbussinessData([]);
      }
      console.log(selectedItems)
    },
    onSelect: (row, isSelect, rows) => {

      if (isSelect) {
        setbussinessData(bussinessData => [...bussinessData, row.id]);
        setError(false)
      }
      else {
        setbussinessData(bussinessData => bussinessData.filter((item) => item !== row.id));
      }
    }
  };
  console.log(bussinessData)

  const pageOptions = {
    sizePerPage: 10,
    totalSize: folloupList.length,
    custom: true,
  }
  const { SearchBar } = Search;

  document.title = "Admin Pannel | Brand eManager";
  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid className="position-relative">
            <Row>
              <Col sm={6}>
                <h4>Admin Pannel</h4>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={folloupList}
                  >

                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={folloupList}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {
                                error ? <>
                                  <div className='error-message text-center'>
                                    <p>Please Select Business</p>
                                  </div></> : <></>
                              }
                              {
                                validation ? <>
                                  <div className='error-message text-center'>
                                    Please select user
                                  </div>
                                </> : <></>
                              }
                              <Col md="8">

                                <div className="d-flex assignuser-wrap">
                                  <div className="assignuser-dropdown">
                                    <select className="form-select" onChange={e => onChangeDropDown(e.target.value)}>
                                      <option value="none" selected disabled hidden>Select User</option>
                                      {
                                        userData.map(list => {
                                          return (
                                            <option key={list.id} value={list.id}>{list.first_name}{list.last_name}</option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                  <div>
                                    <Button type="button" color="primary" onClick={handleSubmit}>Assign user</Button>
                                  </div>
                                </div>
                              </Col>
                              {/* <Col md="2">
                                <Button type="button" color="primary" onClick={handleSubmit}>Assign user</Button>
                              </Col> */}
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    columns={columns}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-bordered customize-table"
                                    }
                                    headerWrapperClasses={"table-dark"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </>
  );
}


export default RenewStatus;
