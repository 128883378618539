import request from "api";
import { endpoints } from "api/endpoints";

export const paymentList = async () => {
    try {
        return await request.get(endpoints.getpayment);
    } catch (error) {
        return false;
    }
};

export const userDataList = async () => {
    try {
        return await request.get(endpoints.getUserData);
    } catch (error) {
        return false;
    }
};