import React, { useState } from 'react'
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Label, Button } from "reactstrap";
import { useEffect } from 'react';
import { getbusinessDetails } from './api';
import { useLocation } from 'react-router-dom';
import { businessDetailsUpdate } from './api';
import { addBusinessDetails } from './api';
import moment from 'moment'

function BusinessDetails() {

    const [businessData, setBusinessData] = useState("");
    const [showData, setShowData] = useState(false);
    const [updatedData, setUpdatedData] = useState('');

    const loaction = useLocation();
    let id = loaction.pathname.slice(27);
    // console.log(location, "location")

    const columns = [
        {
            dataField: "business_id",
            text: "Business id",
            sort: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },
        {
            dataField: "contact",
            text: "Contact",
            sort: true,
        }
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: businessData.length, // replace later with size(customers),
        custom: true,
    };

    const getData = async (id) => {
        const response = await getbusinessDetails(id);
        if (response) {
            setBusinessData(response.data);
        }
    };
    console.log(businessData, "businessData")


    const handleChange = (e) => {
        const { name, value } = e.target
        setBusinessData({ ...businessData, [name]: value })
        setUpdatedData({ ...updatedData, [name]: value })
        console.log(e.target.value)
    }

    const handleSubmit = async (id) => {
        if (id) {
            console.log(id, "id")
            console.log(updatedData, "updatedData")
            const response = await businessDetailsUpdate(id, updatedData)
            if (response) {
                setBusinessData(response.data)
                console.log(response, "response")
                setShowData(false)
            }
        }
        else {
            const response = await addBusinessDetails(updatedData);
            if (response) {
                setShowData(false);
            }
        }
    }

    const handleEdit = () => {
        setShowData(true)
    }

    useEffect(() => {
        if (id) {
            getData(id);
        }
        else {
            setShowData(true);
        }
    }, []);


    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid className='d-flex justify-content-between align-items-center mb-3'>
                        <h4>Business Details</h4>
                        <div className='d-flex'>
                            {
                                showData ?
                                    <>
                                        <Button type="button" color="primary" onClick={() => handleSubmit(businessData.business_id)}>Submit</Button>
                                    </>
                                    : <>
                                        <Button type="button" color="primary" onClick={handleEdit} className="d-flex justify-content-between align-items-center"><i className='bx bx-edit me-2 '></i>Edit</Button>
                                    </>
                            }
                        </div>
                    </Container>
                    <div className="row">
                        <div className="col-lg-6">
                            <Card>
                                <CardBody className='business-details'>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>
                                                Name
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='name' value={businessData.name} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{businessData.name}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>Email</Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='email' value={businessData.email} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{businessData.email}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>
                                                Contact
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='contact' value={businessData.contact} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{businessData.contact}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>
                                                Aditional contact
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='aditional_contact' value={businessData.aditional_contact} onChange={handleChange} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Label>{businessData.aditional_contact}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className="businessdata-label">
                                            <Label>
                                                Website
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='website' value={businessData.website} onChange={handleChange} />
                                                    </div></>
                                                :
                                                <>
                                                    <Label>{businessData.website}</Label>
                                                </>
                                        }
                                    </div>
                                    {
                                        id ? <> <div className="d-flex mt-4">
                                            <div className="businessdata-label">
                                                <Label>
                                                    Plan activation time
                                                </Label>
                                            </div>
                                            {
                                                showData ?
                                                    <>
                                                        <div className="businessdata-input">
                                                            <input className="form-control form-control-sm" disabled type="datetime-local" name='plan_activation_time' value={moment(Date(businessData.plan_activation_time)).format('YYYY-MM-DDTHH:mm')} />
                                                        </div>
                                                    </> :
                                                    <>
                                                        <Label>{moment(Date(businessData.plan_activation_time)).format('YYYY-MM-DD')}</Label>
                                                    </>
                                            }
                                        </div></> : <></>
                                    }
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>
                                                Plan
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='plan' value={businessData.plan} onChange={handleChange} />
                                                    </div>
                                                </>
                                                : <>
                                                    <Label>{businessData.plan}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>
                                                User
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='user' value={businessData.user} onChange={handleChange} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Label>{businessData.user}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>
                                                Business category
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="businessdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='business_category' value={businessData.business_category} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{businessData.business_category}</Label>
                                                </>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-lg-6">
                            <Card>
                                <CardBody className='business-details'>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>location</Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='location' value={businessData.location} onChange={handleChange} />
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{businessData.location}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>Area</Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='area' value={businessData.area} onChange={handleChange} />
                                                </div>
                                            </> : <>
                                                <Label>{businessData.area}</Label>
                                            </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>City</Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='city' value={businessData.city} onChange={handleChange} />
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{businessData.city}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>Pincode</Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='pincode' value={businessData.pincode} onChange={handleChange} />
                                                </div>
                                            </> :
                                                <>
                                                    <Label>
                                                        {businessData.pincode}
                                                    </Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="businessdata-label">
                                            <Label>State</Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="businessdata-input">
                                                    <input className="form-control form-control-sm" type="text" name='state' value={businessData.state} onChange={handleChange} />
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{businessData.state}</Label>
                                                </>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    )
}

export default BusinessDetails