import React, { PureComponent, useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import WelcomeCard from "./WelcomeCard";
import PaymentSummery from "./PaymentSummery";
import DownloadImages from "./DownloadImages";
import Business from "./Business";
import User from "./User";
import Transaction from "./Transaction";
import { graphData } from "store/dashboard1/action";

import { useSelector } from "react-redux";


const userData1 = [
  {
    date: 'week',
    count: 4000,
  },
  {
    date: 'Page B',
    count: 3000,
  },
  {
    date: 'Page C',
    count: 2000,
  },
  {
    date: 'Page D',
    count: 2780,
  },
  {
    date: 'Page E',
    count: 1890,
  },
  {
    date: 'Page F',
    count: 2390,
  },
  {
    date: 'Page G',
    count: 3490,
  },
];
const userData2 = [
  {
    date: 'Month',
    count: 4000,
  },
  {
    date: 'Page B',
    count: 3000,
  },
  {
    date: 'Page C',
    count: 2000,
  },
  {
    date: 'Page D',
    count: 2780,
  },
  {
    date: 'Page E',
    count: 1890,
  },
  {
    date: 'Page F',
    count: 2390,
  },
  {
    date: 'Page G',
    count: 3490,
  },
];

const userData3 = [
  {
    date: 'Year',
    count: 4000,
  },
  {
    date: 'Page B',
    count: 3000,
  },
  {
    date: 'Page C',
    count: 2000,
  },
  {
    date: 'Page D',
    count: 2780,
  },
  {
    date: 'Page E',
    count: 1890,
  },
  {
    date: 'Page F',
    count: 2390,
  },
  {
    date: 'Page G',
    count: 3490,
  },
];

const businessData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const downloadImg = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];




function Dashboard(props) {
  console.log("data", props.paymentData)



  const paymentData = useSelector(state => state.paymentData)
  const graphUserData = useSelector(state => state.graphData1)

  const [userData, setUserData] = useState(graphUserData ? (graphUserData[1] ? graphUserData[1].week : null) : null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(graphData())
  }, [])

  const handleWeekdata = () => {
    setUserData(graphUserData ? (graphUserData[1] ? graphUserData[1].week : null) : null)
  }

  const handleMonthdata = () => {
    setUserData(graphUserData ? (graphUserData[1] ? graphUserData[1].month : null) : null)
  }
  const handleYeardata = () => {
    setUserData(graphUserData ? (graphUserData[1] ? graphUserData[1].year : null) : null)
  }

  document.title = "Dashboard | Brand eManager - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Dashboard</h4>
          <Row>
            <Col xl="4">
              <WelcomeCard />
              {/* <MonthlyEarning /> */}
              <PaymentSummery />
            </Col>
            <Col xl="8">
              <Row>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Trial Plan User
                          </p>
                          <h4 className="mb-0">{paymentData ? (paymentData[2] ? paymentData[2].total_payment : "") : ""}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="fas fa-user-friends"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Gold Plan User
                          </p>
                          <h4 className="mb-0">{paymentData ? (paymentData[0] ? paymentData[0].total_payment : "") : ""}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="fas fa-user-friends"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Diamond Plan User
                          </p>
                          <h4 className="mb-0">{paymentData ? (paymentData[1] ? paymentData[1].total_payment : "") : ""}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="fas fa-user-friends"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>



              {/* <Card>
                <CardBody>
                  <div className="graph-btn">
                    <Button type="button" color="primary" onClick={handleWeekdata}>
                      This week
                    </Button>
                    <Button type="button" color="primary" onClick={handleMonthdata}>
                      This month
                    </Button>
                    <Button type="button" color="primary" onClick={handleYeardata}>
                      This year
                    </Button>
                  </div>
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title mb-4 h4">
                      Users
                    </CardTitle>
                    <LineChart
                      width={900}
                      height={350}
                      data={userData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 10,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="count" stroke="#8884d8" />
                    </LineChart>
                  </div>
                </CardBody>
              </Card> */}



            </Col>
          </Row>
          <User />
          <Row>
            <Col xl="6">
              {/* <Card>
                <CardBody>
                  <div className="d-sm-flex flex-werap">
                    <CardTitle className="card-title mb-4 h4">
                      Business
                    </CardTitle>
                    <LineChart
                      width={700}
                      height={450}
                      data={businessData}
                      margin={{
                        right: 30,
                        left: 0,
                        bottom: 5,
                      }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                    </LineChart>
                  </div>
                </CardBody>
              </Card> */}
            </Col>
            <Col xl="6">
              {/* <Card>
                <CardBody>
                  <div className="d-sm-flex flex-werap">
                    <CardTitle className=" mb-4 h4">
                      Download Images
                    </CardTitle>
                    <LineChart
                      width={700}
                      height={450}
                      data={downloadImg}
                      margin={{
                        right: 30,
                        left: 0,
                        bottom: 5,
                      }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                      <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                    </LineChart>
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default Dashboard;

