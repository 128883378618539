import React, { useEffect, useState } from 'react'
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Label, Button } from "reactstrap";
import { businessCategoryDetails } from './api';
import { businessCategoryUpdate } from './api';
import { useLocation } from 'react-router-dom';
import { addBusinessCategory } from './api';

function BusinessCategoryDetails() {
    const initialState = { category_name: '', is_active: false, image: '' }
    const [businessCategoryList, setBusinessCategoryList] = useState([])
    const [showInput, setShowInput] = useState(false);
    const [finalData, setFinalData] = useState()

    const loaction = useLocation();
    let id = loaction.pathname.slice(44)

    useEffect(() => {
        if (id) {
            getData(id);
        }
        else {
            setBusinessCategoryList(initialState)
            setShowInput(true);
        }
    }, []);

    const getData = async (id) => {
        const response = await businessCategoryDetails(id);
        if (response) {
            setBusinessCategoryList(response.data);
        }
    }
    console.log(businessCategoryList, "businessCategoryList")

    const handleChange = (e) => {
        const { name, value } = e.target
        setBusinessCategoryList({ ...businessCategoryList, [name]: value })
        setFinalData({ ...finalData, [name]: value })
    }
    console.log(finalData, "finaldata")

    const handleClick = (e) => {
        const { name, checked } = e.target
        setBusinessCategoryList({ ...businessCategoryList, [name]: checked })
        setFinalData({ ...finalData, [name]: checked })
    }
    const handleChangeImage = (e) => {
        const { name, files } = e.target
        setBusinessCategoryList({ ...businessCategoryList, [name]: files[0] })
        setFinalData({ ...finalData, [name]: files[0] })
    }

    const handleEdit = () => {
        setShowInput(true);
    }

    const handleSubmit = async () => {
        if (id) {
            console.log(finalData, "okok")
            const response = await businessCategoryUpdate(id, finalData);
            if (response) {
                setShowInput(false);
            }
        }
        else {
            const response = await addBusinessCategory(finalData);
            if (response) {
                setShowInput(false);
            }
        }
    }

    // const initialState = { category_name: '', is_active: '', image: '' }
    // const [businessCategoryList, setBusinessCategoryList] = useState(initialState);
    // const [updateImage, setUpdateImage] = useState('')

    // const [showData, setShowData] = useState(false);

    // const loaction = useLocation();
    // let id = loaction.pathname.slice(44)
    // console.log(location, "location")

    // useEffect(() => {
    //     getData(id);
    // }, [showData]);

    // const getData = async (id) => {
    //     const response = await businessCategoryDetails(id);
    //     if (response) {
    //         setBusinessCategoryList({ category_name: response.data.category_name, is_active: response.data.is_active, image: response.data.image })

    //     }
    // }
    // console.log(businessCategoryList.id, "123")

    // const handleChange = (e) => {
    //     const { name, value } = e.target
    //     console.log(e.target.value)
    //     setBusinessCategoryList({ ...businessCategoryList, [name]: value })
    // }

    // const handleSubmit = async () => {
    //     var formdata = new FormData();
    //     formdata.append("is_active", businessCategoryList.is_active);
    //     formdata.append("category_name", businessCategoryList.category_name);
    //     if (updateImage.name) {
    //         formdata.append("image", updateImage, updateImage.name);
    //     }

    //     const response = await businessCategoryUpdate(id, formdata)
    //     if (response) {
    //         setShowData(false)
    //     }
    // }

    // const handleClick = (e) => {
    //     const { name, checked } = e.target
    //     setBusinessCategoryList({ ...businessCategoryList, [name]: checked })

    // }

    // const handleChangeImg = (e) => {
    //     const { name, files } = e.target
    //     if (e.target.files) {
    //         // console.log("e.target.files", e.target.files[0].name);
    //         setUpdateImage(e.target.files[0]);

    //     }
    // }

    // const handleEdit = () => {
    //     setShowData(true)
    // }

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Business Category</h4>
                        <div className="d-flex">
                            {
                                showInput ? <>
                                    <Button type="button" color="primary" onClick={handleSubmit}>Submit</Button>
                                </> :
                                    <>
                                        <Button type="button" color="primary" onClick={handleEdit} className="d-flex justify-content-between align-items-center"><i className='bx bx-edit me-2 '></i>Edit</Button>
                                    </>
                            }
                        </div>
                    </Container>

                    <div className="row">
                        <div className='col-lg-6'>
                            <Card>
                                <CardBody className='business-details'>
                                    <div className='d-flex mt-4'>
                                        <div className="businessdata-label">
                                            <Label>
                                                Category name
                                            </Label>
                                        </div>
                                        {
                                            showInput ? <>
                                                <div className='businessdata-input'>
                                                    <input className="form-control form-control-sm" type="text" name='category_name' value={businessCategoryList.category_name} onChange={handleChange} />
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{businessCategoryList.category_name}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className="businessdata-label">
                                            <Label>
                                                Is active
                                            </Label>
                                        </div>
                                        {
                                            showInput ?
                                                <>
                                                    <div className="userdata-input">
                                                        <label className="switch small-switch">
                                                            <input type="checkbox" className="form-check-input" role="switch" name='is_active' checked={businessCategoryList.is_active} onClick={handleClick} />
                                                            <span className="slider round slim"></span>
                                                        </label>
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{businessCategoryList.is_active ? "Yes" : "No"}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='businessdata-label'>
                                            <Label>
                                                Image
                                            </Label>
                                        </div>
                                        <div className="businessdata-input">
                                            {!showInput && <img src={businessCategoryList.image} style={{ width: "70px" }} ></img>}
                                            {showInput && <input className="form-control form-control-sm" accept="image/png, image/jpeg" type="file" name='image' onChange={handleChangeImage} />}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    )
}

export default BusinessCategoryDetails