import React, { useState } from 'react'
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Button, } from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useEffect } from 'react';
// import { usersData } from './api';
import { Link } from "react-router-dom"
import { getbusinessData } from './api';
import moment from 'moment'


function Business() {

    const [businessData, setBusinessData] = useState([])
    // const [showData, setShowData] = useState = (false)
    const columns = [
        {
            dataField: "business_id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "business_category",
            text: "Business Category",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },
        {
            dataField: "contact",
            text: "Contact",
            sort: true,
        },
        {
            dataField: "user",
            text: "User",
            sort: true,
        },
        {
            dataField: "plan",
            text: "Plan",
            sort: true,
        },
        {
            dataField: "plan_activation_time",
            text: "Plan Activation Time",
            sort: true,
            formatter: (date) => (
                <tr>
                    {
                        date !== null ?
                            moment((date)).format('DD-MM-YYYY HH:MM') : ""
                    }
                </tr>
            )
        },
        {
            dataField: "website",
            text: "Website",
            sort: true,
        },
        {
            dataField: "download_count",
            text: "Download Count",
            sort: true,
        },
        {
            dataField: "",
            text: "Edit",
            sort: true,
            formatter: (DayCellContent, businessData) => (
                <>
                    <Link to={"/business/business-details/" + businessData.business_id} className="text-dark">
                        <i className="dripicons-document-edit" />
                    </Link>
                </>
            )
        }
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: businessData.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;


    const getBusiness = async () => {
        const response = await getbusinessData();
        if (response) {
            setBusinessData(response.data);
        }
        console.log(businessData, "userdata")
    };

    useEffect(() => {
        getBusiness();
    }, []);

    document.title = "Business | Brand eManager";

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h4>Business</h4>
                    </Container>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columns} data={businessData}>
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={businessData}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="8">
                                                                <div className="d-flex justify-content-end">
                                                                    <Link to="/business/business-details">
                                                                        <Button type="button" color="primary" className='d-flex justify-content-end align-items-center'>
                                                                            <i className='bx bx-plus me-2 '></i>Add Business
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        // selectRow={selectRow}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-bordered customize-table"
                                                                        }
                                                                        headerWrapperClasses={"table-dark"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <button className="add-btn">
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </button> */}
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </>
    )
}

export default Business