import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle } from "reactstrap"
import { Row, Col, Input, Label, Nav, NavItem, NavLink, Table, Container } from "reactstrap"
import { userDataList } from './api'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"


const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];


function User() {

    const [userData, setUserData] = useState({})

    useEffect(() => {
        getuserdata();
    }, []);

    const getuserdata = async () => {
        const response = await userDataList();
        if (response) {
            setUserData(response.data.payload);
            console.log(response.data.payload, "userDataList")
        }
        console.log(userData, "userdata")
        console.log(userData.Users.total, "total")
    };

    return (
        <>
            <Row>
                <Col xl="4">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4 h4 text-center">USERS</CardTitle>
                            <div className="text-center">
                                <div className="mb-4">
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center mx-auto ">
                                        <span className="avatar-title rounded-circle">
                                            <i className="fas fa-user-friends"></i>
                                        </span>
                                    </div>
                                </div>
                                <h3>{userData ? (userData.Users ? userData.Users[0].total : null) : null}</h3>
                                <p>Total</p>
                            </div>
                            <div className="table-responsive mt-4">
                                <table className="table align-middle table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "100%" }}>
                                                <p className="mb-0">Today</p>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Users[0].today : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Week</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Users[0].week : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Month</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Users[0].month : null) : null}</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="4">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4 h4 text-center">BUSINESS</CardTitle>
                            <div className="text-center">
                                <div className="mb-4">
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center mx-auto ">
                                        <span className="avatar-title rounded-circle">
                                            <i className="bx bxs-business"></i>
                                        </span>
                                    </div>
                                </div>
                                <h3>{userData ? (userData.Users ? userData.Business[0].total : null) : null}</h3>
                                <p>Total</p>
                            </div>
                            <div className="table-responsive mt-4">
                                <table className="table align-middle table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "100%" }}>
                                                <p className="mb-0">Today</p>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Business[0].today : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Week</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Business[0].week : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Month</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Business[0].month : null) : null}</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="4">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4 h4 text-center">DOWNLOAD IMAGES</CardTitle>
                            <div className="text-center">
                                <div className="mb-4">
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center mx-auto ">
                                        <span className="avatar-title rounded-circle">
                                            <i className="fas fa-download"></i>
                                        </span>
                                    </div>
                                </div>
                                <h3>{userData ? (userData.Users ? userData.Download_Image[0].total : null) : null}</h3>
                                <p>Total</p>
                            </div>
                            <div className="table-responsive mt-4">
                                <table className="table align-middle table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "100%" }}>
                                                <p className="mb-0">Today</p>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Download_Image[0].today : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Week</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Download_Image[0].week : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Month</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Download_Image[0].month : null) : null}</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="4">
                    <Card>
                        <CardBody>
                            <CardTitle className="mb-4 h4 text-center">Transaction</CardTitle>
                            <div className="text-center">
                                <div className="mb-4">
                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center mx-auto ">
                                        <span className="avatar-title rounded-circle">
                                            <i className="fas fa-user-friends"></i>
                                        </span>
                                    </div>
                                </div>
                                <h3>{userData ? (userData.Users ? userData.Transaction[0].total : null) : null}</h3>
                                <p>Total</p>
                            </div>
                            <div className="table-responsive mt-4">
                                <table className="table align-middle table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "100%" }}>
                                                <p className="mb-0">Today</p>
                                            </td>
                                            <td style={{ width: "25%" }}>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Transaction[0].today : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Week</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Transaction[0].week : null) : null}</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0">This Month</p>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{userData ? (userData.Users ? userData.Transaction[0].month : null) : null}</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    {/* <Card>
                        <CardBody>
                            <div className='d-sm-flex flex-wrap'>
                                <CardTitle className='card-title mb-4 h4'>
                                    Transaction
                                </CardTitle>
                                <LineChart
                                    width={900}
                                    height={340}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 10,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                                </LineChart>
                            </div>
                        </CardBody>
                    </Card> */}
                </Col>
            </Row>
        </>
    )
}

export default User