import React, { useState, useEffect } from 'react'
import { Card, CardBody, Col, Input, Label, Nav, NavItem, NavLink, Table } from "reactstrap"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import { paymentList } from './api'
import { fetchPayment } from 'store/dashboard1/action'
import { connect } from 'react-redux'

function PaymentSummery(props) {

    const [activeTabs, setAxtiveTabs] = useState("GOLD");
    const [paymentData, setPaymentData] = useState([]);

    useEffect(() => {
        getpaymentSummery();
        props.fetchPayment()
    }, []);

    const getpaymentSummery = async () => {
        const response = await paymentList();
        if (response) {
            setPaymentData(response.data.payload);
            console.log(response.data.payload, "paymentListtt")
        }
    };

    // console.log(paymentData.Trial, "paymentdata")
    // console.log(paymentData.GOLD.plan_name, "GOLD")
    // console.log(paymentData.DIAMOND, "DIAMOND")

    const tabToggleBtn = (id) => {
        if (activeTabs !== id) {
            setAxtiveTabs(id)
        }
    }


    return (
        <>
            <React.Fragment>
                <Col xl="12">
                    <Card>

                        <CardBody>
                            <h4 className="card-title mb-4">Payment Summery</h4>

                            <Nav pills className="bg-light rounded">
                                {
                                    paymentData.map(list => {
                                        return (
                                            <NavItem key={list.plan_name}>
                                                <NavLink className={`${activeTabs === list.plan_name && "active"}`} onClick={() => tabToggleBtn(list.plan_name)} >
                                                    {list.plan_name}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })
                                }
                            </Nav>
                            <Col sm="12">
                                <div className="table-responsive mt-4">
                                    <table className="table align-middle table-nowrap">
                                        <tbody>
                                            <tr>
                                                {
                                                    paymentData.map(list => {
                                                        return (
                                                            list.plan_name === activeTabs ?
                                                                <>
                                                                    <tr>
                                                                        <td style={{ width: "100%" }} key={list.purchase_count}>
                                                                            <p>Purchase Count</p>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{list.purchase_count}</h5>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p>Plan Price</p>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{list.plan_price}</h5>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <p>Total Count</p>
                                                                        </td>
                                                                        <td>
                                                                            <h5>{list.total_payment}</h5>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                ""
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        </>
    )
}


// const mapStateToProps = (state) => {
//     return { paymentData: state.paymentData }
// }

export default connect(null, { fetchPayment })(PaymentSummery)