import React, { useState } from 'react'
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Label, Button } from "reactstrap";
import { useLocation } from 'react-router-dom';
import { getPlanDetails } from './api';
import { planDetailsUpdate } from './api';
import { addPlan } from './api';
import { useEffect } from 'react';

function PlanDetails() {

    const initial = { name: '', price: '', discount_price: '', discount: '', day_count: '', month_count: '', description: '', daily_image_count: '', is_default: '', is_trial_plan: '', is_premium: '', is_active: '' }
    const initialState = { festival_image_per_day: '', premium_image_per_day: '', remove_watermark: '', ads_free_experience: '', custom_frame: '', custom_image: '', business_category_images: '', greeting_images: '' }

    const [planDetails, setPlanDetails] = useState("")
    const [planUpdate, setPlanUpdate] = useState("")
    const [planId, setPlanId] = useState("")
    const [finalPlanDetails, setFinalPlanDetails] = useState("");
    const [finalPlanUpdate, setFinalPlanUpdate] = useState('');
    const [showInput, setShowInput] = useState(false);


    const loaction = useLocation();
    let id = loaction.pathname.slice(19);


    useEffect(() => {
        if (id) {
            getData(id);
        }
        else {
            setPlanDetails(initial);
            setPlanUpdate(initialState);
        }
    }, [id]);

    const getData = async (id) => {
        const response = await getPlanDetails(id);
        if (response) {
            setPlanDetails(response.data.plan);
            setPlanUpdate(response.data)
            setPlanId(response.data.new_plan_detail_id);
        }
    };

    useEffect(() => {
        setFinalPlanUpdate(planId)
    }, [planDetails]);


    const handleChange = (e) => {
        const { name, value } = e.target
        setPlanDetails({ ...planDetails, [name]: value })
        setFinalPlanDetails({ ...finalPlanDetails, [name]: value })
    }

    const handleClick = (e) => {
        const { name, checked } = e.target
        setPlanDetails({ ...planDetails, [name]: checked })
        setFinalPlanDetails({ ...finalPlanDetails, [name]: checked })
    }

    const handleChangedata = (e) => {
        const { name, value } = e.target
        setPlanUpdate({ ...planUpdate, [name]: value })
        setFinalPlanUpdate({ ...finalPlanUpdate, [name]: value })
    }

    const handleUpdate = (e) => {
        const { name, checked } = e.target
        setPlanUpdate({ ...planUpdate, [name]: checked })
        setFinalPlanUpdate({ ...finalPlanUpdate, [name]: checked })
    }

    const handleEdit = () => {
        setShowInput(true);
    }

    const handleSubmit = async () => {

        if (id) {
            var params = { ...finalPlanDetails, ...finalPlanUpdate, ["plan"]: planId }
            const response = await planDetailsUpdate(params);
            if (response) {
                console.log(response.data.payload, "Api successfully updated")
                setShowInput(false);
            }
        }
        else {
            console.log(finalPlanDetails, "finalPlanDetails")
            console.log(finalPlanUpdate, "finalPlanUpdate")
            var newparams = { ...finalPlanDetails, ...finalPlanUpdate }

            console.log(newparams, "newparams")
            const response = await addPlan(newparams)

            if (response) {
                setShowInput(false);
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className='d-flex justify-content-between align-items-center mb-3'>
                    <h4>Plan Details</h4>
                    <div className="d-flex">
                        {
                            showInput ?
                                <>
                                    <Button type="button" color="primary" onClick={handleSubmit}>Submit</Button>
                                </>
                                :
                                <>
                                    <Button type="button" color="primary" onClick={handleEdit} className="d-flex justify-content-between align-items-center"><i className='bx bx-edit me-2 '></i>Edit</Button>
                                </>
                        }
                    </div>
                </Container>
                <div className="row">
                    <div className="col-lg-6">
                        <Card>
                            <CardBody className='business-details'>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Name
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='name' value={planDetails.name} onChange={handleChange} />
                                            </> :
                                                <>
                                                    <Label>{planDetails.name}</Label>
                                                </>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Price</Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ?
                                                <>
                                                    <input className="form-control form-control-sm" type="text" name='price' value={planDetails.price} onChange={handleChange} />
                                                </> : <>
                                                    <Label>{planDetails.price}</Label>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Discount price
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ?
                                                <>
                                                    <input className="form-control form-control-sm" type="text" name='discount_price' value={planDetails.discount_price} onChange={handleChange} />
                                                </> : <>
                                                    <Label>{planDetails.discount_price}</Label>
                                                </>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Discount
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ?
                                                <>
                                                    <input className="form-control form-control-sm" type="text" name='discount' value={planDetails.discount} onChange={handleChange} />
                                                </> : <>
                                                    <Label>{planDetails.discount}</Label>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex mt-4'>
                                    <div className="businessdata-label">
                                        <Label>
                                            Day count
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='day_count' value={planDetails.day_count} onChange={handleChange} /></> :
                                                <>
                                                    <Label>{planDetails.day_count}</Label>
                                                </>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Month count
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='month_count' value={planDetails.month_count} onChange={handleChange} />
                                            </> : <>
                                                <Label>{planDetails.month_count}</Label>
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Description
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='description' value={planDetails.description} onChange={handleChange} />
                                            </> : <>
                                                <Label>{planDetails.description}</Label>
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Daily image count
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='daily_image_count' value={planDetails.daily_image_count} onChange={handleChange} />
                                            </> :
                                                <>
                                                    <Label>{planDetails.daily_image_count}</Label>
                                                </>
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-6">
                        <Card>
                            <CardBody className='business-details'>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is default
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_default' checked={planDetails.is_default ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planDetails.is_default ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is trial plan
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_trial_plan' checked={planDetails.is_trial_plan ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planDetails.is_trial_plan ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is premium
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_premium' checked={planDetails.is_premium ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planDetails.is_premium ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is active
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_active' checked={planDetails.is_active ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planDetails.is_active ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-6">
                        <Card>
                            <CardBody>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Festival image per day
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='festival_image_per_day' value={planUpdate.festival_image_per_day} onChange={handleChangedata} />
                                            </> : <>
                                                <Label>{planUpdate.festival_image_per_day}</Label>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Premium image per day
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input className="form-control form-control-sm" type="text" name='premium_image_per_day' value={planUpdate.premium_image_per_day} onChange={handleChangedata} />
                                            </> : <>
                                                <Label>{planUpdate.premium_image_per_day}</Label>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Remove watermark
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='remove_watermark' checked={planUpdate.remove_watermark ? true : false} onClick={handleUpdate} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planUpdate.remove_watermark ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Ads free experience
                                        </Label>
                                    </div>
                                    <div className="switch small-switch">
                                        <label>
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='ads_free_experience' checked={planUpdate.ads_free_experience ? true : false} onClick={handleUpdate} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planUpdate.ads_free_experience ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Custom frame
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <>
                                                <input type="number" name='custom_frame' value={planUpdate.custom_frame} onChange={handleChangedata} />
                                            </> : <>
                                                <Label>{planUpdate.custom_frame}</Label>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Custom image</Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='custom_image' checked={planUpdate.custom_image ? true : false} onClick={handleUpdate} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planUpdate.custom_image ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Business category images</Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='business_category_images' checked={planUpdate.business_category_images ? true : false} onClick={handleUpdate} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planUpdate.business_category_images ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Greeting image</Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='greeting_images' checked={planUpdate.greeting_images ? true : false} onClick={handleUpdate} />
                                                    <span className="slider round slim"></span>
                                                </> : <>
                                                    <Label>{planUpdate.greeting_images ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PlanDetails