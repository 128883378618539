import request from "../../api";
import { endpoints } from "../../api/endpoints";

export const followpList = async () => {
  try {
    return await request.get(endpoints.renewFollowup);
  } catch (error) {
    return false;
  }
};

export const planFollowupList = async (id) => {
  try {
    return await request.get(`${endpoints.getCommentData}${id}/`);
  } catch (error) {
    return false;
  }
}

export const addComments = async (params) => {
  try {
    return await request.post(endpoints.getCommentData, params);
  } catch (error) {
    return false;
  }
}


// export const updateFrameStatus = async (id, params) => {
//   try {
//     return await request.put(`${endpoints.newUserData}${id}/`, params);
//   } catch (error) {
//     return false;
//   }
// };
