import React, { useState } from 'react'
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Container,
} from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useEffect } from 'react';
import { usersData } from './api';
import { Link } from "react-router-dom"



function Users() {

    const [userData, setUserData] = useState([]);

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
        },
        {
            dataField: "first_name",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "last_name",
            text: "Last Name",
            sort: true,
        },
        {
            dataField: "",
            text: "Edit",
            sort: true,
            formatter: (DayCellContent, userData) => (
                <>
                    <Link
                        to={
                            "/users/users-details/" + userData.id
                        }
                        className="text-dark"
                    >

                        <i className="dripicons-document-edit" />

                    </Link>

                </>
            )
        }
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const selectRow = {
        mode: "checkbox",
    }

    const pageOptions = {
        sizePerPage: 10,
        totalSize: usersData.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;

    const getUserData = async () => {
        const response = await usersData();
        if (response) {
            setUserData(response.data);
        }
        console.log(userData, "userdata")
    };

    useEffect(() => {
        getUserData();
    }, []);

    document.title = "Users | Brand eManager";

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <h4>Users</h4>
                    </Container>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={columns} data={userData}>
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={userData}
                                                selectRow={selectRow}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        // selectRow={selectRow}
                                                                        classes={
                                                                            "table align-middle table-nowrap table-bordered customize-table"
                                                                        }
                                                                        headerWrapperClasses={"table-dark"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </>
    )
}

export default Users