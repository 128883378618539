import React, { useState } from 'react'
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Container,
    Label,
    Button
} from "reactstrap";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useEffect } from 'react';
import { usersDetails } from './api';
import { Link, useLocation } from "react-router-dom";
import { Form, FormGroup, Input } from 'reactstrap';
import { userDetailsUpdate } from './api';


function Users() {
    const initialData = { email: '', phone: '', first_name: '', last_name: '', user_role: '', business_count: '', is_email_verified: '', is_trial_plan_taken: '', is_superuser: '', is_active: '', is_private: '' }

    const [userData, setUserData] = useState(initialData);
    const [showData, setShowData] = useState(false);

    const loaction = useLocation()
    let id = loaction.pathname.slice(21);

    // const history = useHistory()

    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
        },
        {
            dataField: "phone",
            text: "Phone",
            sort: true,
        },
        {
            dataField: "first_name",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "last_name",
            text: "Last Name",
            sort: true,
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const selectRow = {
        mode: "checkbox",
    }

    const pageOptions = {
        sizePerPage: 10,
        totalSize: usersDetails.length, // replace later with size(customers),
        custom: true,
    };

    const { SearchBar } = Search;

    const getUserDetails = async (id) => {
        const response = await usersDetails(id);
        if (response) {
            setUserData({ email: response.data.email, phone: response.data.phone, first_name: response.data.first_name, last_name: response.data.last_name, user_role: response.data.user_role, business_count: response.data.business_count, is_email_verified: response.data.is_email_verified, is_trial_plan_taken: response.data.is_trial_plan_taken, is_superuser: response.data.is_superuser, is_active: response.data.is_active, is_private: response.data.is_private });
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value })
    }

    const handleClicked = (e) => {
        const { name, checked } = e.target
        setUserData({ ...userData, [name]: checked })
    }
    console.log(userData, "userdaataa")

    const handleSubmit = async () => {
        const response = await userDetailsUpdate(id, userData);
        if (response.status == 200) {
            // history.push("/users")
            setShowData(false)
        }
    }

    const handleEdit = () => {
        setShowData(true)
    }

    useEffect(() => {
        getUserDetails(id);
    }, []);

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid className='d-flex justify-content-between mb-3'>
                        <h4>Users Details</h4>
                        <div className="d-flex">
                            {
                                showData ? <>
                                    <Button type="button" color="primary" onClick={handleSubmit}>Submit</Button>
                                </> :
                                    <>
                                        <Button type="button" color="primary" onClick={handleEdit} className="d-flex justify-content-between align-items-center"><i className='bx bx-edit me-2 '></i>Edit</Button>
                                    </>
                            }
                        </div>
                    </Container>
                    {/* <div className="row">
                        <div className="col-4">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 h4">User Information</CardTitle>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Email
                                        </p>
                                        <h5>{userData ? (userData.email == null ? '-' : userData.email) : null}</h5>
                                    </div>


                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            phone
                                        </p>
                                        <h5>{userData ? (userData.phone == null ? '-' : userData.phone) : null}</h5>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-4">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 h4">Personal Information</CardTitle>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            First Name
                                        </p>
                                        <h5>{userData ? (userData.first_name == "" ? '-' : userData.first_name) : null}</h5>
                                    </div>

                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Last Name
                                        </p>
                                        <h5>{userData ? (userData.last_name == "" ? '-' : userData.last_name) : null}</h5>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-4">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 h4">Details</CardTitle>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            User role
                                        </p>
                                        <h5>{userData ? (userData.user_role ? userData.user_role : "-") : null}</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Business count
                                        </p>
                                        <h5>{userData ? (userData.business_count ? userData.business_count : "-") : null}</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Uid
                                        </p>
                                        <h5>{userData ? (userData.uid == "" ? '-' : userData.uid) : null}</h5>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-4">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 h4">Permissions</CardTitle>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Is email verified
                                        </p>
                                        <h5>{userData ? (userData.is_email_verified ? "Yes" : "No") : null}</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Is trial plan taken
                                        </p>
                                        <h5>{userData ? (userData.is_email_verified ? "Yes" : "No") : null}</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Is superuser
                                        </p>
                                        <h5>{userData ? (userData.is_superuser ? "Yes" : "No") : null}</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Is active
                                        </p>
                                        <h5>{userData ? (userData.is_active ? "Yes" : "No") : null}</h5>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className="text-muted fw-medium m-0">
                                            Is private
                                        </p>
                                        <h5>{userData ? (userData[0] ? (userData[0].is_private ? "Yes" : "No") : null) : null}</h5>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-6">
                            <Card>
                                <CardBody className="users-details">
                                    <CardTitle>User Information</CardTitle>
                                    <div className='d-flex mt-4'>
                                        <div className="userdata-label">
                                            <Label>
                                                Email
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='email' value={userData.email == '' ? '' : userData.email} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{userData.email == '' ? '' : userData.email}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>Phone</Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='phone' value={userData.phone == '' ? '' : userData.phone} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{userData.phone == '' ? '' : userData.phone}</Label>
                                                </>
                                        }

                                    </div>
                                    <hr />
                                    <CardTitle>Personal Information</CardTitle>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label> First Name</Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='first_name' value={userData.first_name == '' ? '' : userData.first_name} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{userData.first_name == '' ? '' : userData.first_name}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Last Name
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='last_name' value={userData.last_name == '' ? '' : userData.last_name} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{userData.last_name == '' ? '' : userData.last_name}</Label>
                                                </>
                                        }

                                    </div>
                                    <hr />
                                    <CardTitle>Details</CardTitle>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                User role
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='user_role' value={userData.user_role == '' ? '' : userData.user_role} onChange={handleChange} />
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{userData.user_role == '' ? '' : userData.user_role}</Label>
                                                </>
                                        }

                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Business count
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <input className="form-control form-control-sm" type="text" name='business_count' value={userData.business_count == '' ? '' : userData.business_count} onChange={handleChange} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Label>{userData.business_count == '' ? '' : userData.business_count}</Label>
                                                </>
                                        }

                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-4">
                            <Card>
                                <CardBody className="users-details">
                                    <CardTitle>Permissions</CardTitle>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Is email verified
                                            </Label>
                                        </div>
                                        {
                                            showData ?
                                                <>
                                                    <div className="userdata-input">
                                                        <label className="switch small-switch">
                                                            <input type="checkbox" className="form-check-input" role="switch" name='is_email_verified' checked={userData.is_email_verified ? true : false} onClick={handleClicked} />
                                                            <span className="slider round slim"></span>
                                                        </label>
                                                    </div>
                                                </> :
                                                <>
                                                    <Label>{userData.is_email_verified ? "Yes" : "No"}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Is trial plan taken
                                            </Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="userdata-input">
                                                    <label className="switch small-switch">
                                                        <input type="checkbox" className="form-check-input" name='is_trial_plan_taken' role="switch" checked={userData.is_trial_plan_taken ? true : false} onClick={handleClicked} />
                                                        <span className="slider round slim"></span>
                                                    </label>
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{userData.is_trial_plan_taken ? "Yes" : "No"}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Is superuser
                                            </Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="userdata-input">
                                                    <label className="switch small-switch">
                                                        <input type="checkbox" className="form-check-input" name='is_superuser' role="switch" checked={userData.is_superuser ? true : false} onClick={handleClicked} />
                                                        <span className="slider round slim"></span>
                                                    </label>
                                                </div>
                                            </> :
                                                <>
                                                    <Label>
                                                        {userData.is_superuser ? "Yes" : "No"}
                                                    </Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Is active
                                            </Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="userdata-input">
                                                    <label className="switch small-switch">
                                                        <input type="checkbox" className="form-check-input" name='is_active' role="switch" checked={userData.is_active ? true : false} onClick={handleClicked} />
                                                        <span className="slider round slim"></span>
                                                    </label>
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{userData.is_active ? "Yes" : "No"}</Label>
                                                </>
                                        }
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="userdata-label">
                                            <Label>
                                                Is private
                                            </Label>
                                        </div>
                                        {
                                            showData ? <>
                                                <div className="userdata-input">
                                                    <label className="switch small-switch">
                                                        <input type="checkbox" className="form-check-input" name='is_private' role="switch" checked={userData.is_private ? true : false} onClick={handleClicked} />
                                                        <span className="slider round slim"></span>
                                                    </label>
                                                </div>
                                            </> :
                                                <>
                                                    <Label>{userData.is_private ? "Yes" : "No"}</Label>
                                                </>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    )
}

export default Users