import request from "api";
import { endpoints } from "api/endpoints";

// export const assignUserList = async () => {
//     try {
//         return await request.get(endpoints.assignUserData);
//     } catch (error) {
//         return false;
//     }
// };

export const userList = async () => {
    try {
        return await request.get(endpoints.userList);
    } catch (error) {
        return false;
    }
};

export const assignUserFollowup = async (params) => {
    try {
        return await request.post(endpoints.assignUser, params);
    } catch (error) {
        return false;
    }
};


