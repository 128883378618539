import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

function ConfirmationModal(props) {
  return (
    <React.Fragment>
      <div className="conf-modal-container">
        <ModalBody>
          <div className="text-center mt-3 mb-4">
            <i className="bx bx-info-circle text-primary"></i>
          </div>
          <p className="text-center">Are you sure, {props.text}?</p>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button type="button" color="primary" onClick={props.handleApi}>
            Confirm
          </Button>
          <Button type="button" color="secondary" onClick={props.closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </div>
      <div className="modal-bg-overlay" onClick={props.closeModal}></div>
    </React.Fragment>
  );
}

export default ConfirmationModal;
