import React, { useState } from 'react'
import { useEffect } from 'react';
import { Table, Row, Col, Card, CardBody, CardTitle, Container, Label, Button } from "reactstrap";
import { categoriesImageDetail } from './api';
import { useLocation } from 'react-router-dom';
import { categoryImages, addCategoryImages } from './api';

function CategoryImg() {
    const initial = { category: '', thumbnail: '', is_premium: false, }
    const [categoriesList, setCategoriesList] = useState([])
    const [showInput, setShowInput] = useState(false)
    const [finalData, setFinalData] = useState()

    const loaction = useLocation();
    let id = loaction.pathname.slice(29);

    useEffect(() => {
        if (id) {
            getData(id);
            console.log(id, "id")
        }
        else {
            setShowInput(true)
        }
    }, []);

    const getData = async (id) => {
        const response = await categoriesImageDetail(id);
        if (response) {
            setCategoriesList(response.data)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setCategoriesList({ ...categoriesList, [name]: value })
        setFinalData({ ...finalData, [name]: value })
        console.log(finalData, "finaldata")
    }

    const handleClick = (e) => {
        const { name, checked } = e.target
        setCategoriesList({ ...categoriesList, [name]: checked })
        setFinalData({ ...finalData, [name]: checked })
    }

    const handleChangeImage = (e) => {
        const { name, files } = e.target
        if (e.target.files) {
            setCategoriesList({ ...categoriesList, [name]: files[0] })
            setFinalData({ ...finalData, [name]: files[0] })
        }
    }

    const handleEdit = () => {
        setShowInput(true);
    }

    const handleSubmit = async () => {
        if (id) {
            console.log(finalData, "setFinalData")
            const response = await categoryImages(id, finalData);
            if (response) {
                setShowInput(false);
            }
        }
        else {
            const response = await addCategoryImages(finalData);
            if (response) {
                setShowInput(false);
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className='d-flex justify-content-between align-items-center mb-3'>
                    <h4>Category Images</h4>
                    <div className='d-flex'>
                        {
                            showInput ? <>
                                <Button type="button" color="primary" onClick={handleSubmit}>Submit</Button>
                            </>
                                :
                                <>
                                    <Button type="button" color="primary" onClick={handleEdit} className="d-flex justify-content-between align-items-center"><i className='bx bx-edit me-2 '></i>Edit</Button>
                                </>
                        }
                    </div>
                </Container>
                <div className="row">
                    <div className="col-lg-6">
                        <Card>
                            <CardBody className='business-details'>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Category
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ?
                                                <>
                                                    <input className="form-control form-control-sm" type="text" name='category' value={categoriesList.category} onChange={handleChange} />
                                                </>
                                                :
                                                <>
                                                    <Label>{categoriesList.category}</Label>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>Image</Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {!showInput && <img src={categoriesList.thumbnail} style={{ width: "50px" }} ></img>}
                                        {showInput && <input className="form-control form-control-sm" type='file' name='image' onChange={handleChangeImage}></input>}
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is Premium
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_premium' checked={categoriesList.is_premium ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span></> : <>
                                                    <Label>{categoriesList.is_premium ? "Yes" : "No"}</Label>
                                                </>
                                            }
                                        </label>
                                    </div>
                                </div>
                                <div className='d-flex mt-4'>
                                    <div className="businessdata-label">
                                        <Label>
                                            Description
                                        </Label>
                                    </div>
                                    <div className="businessdata-input">
                                        {
                                            showInput ? <><input className="form-control form-control-sm" type="text" name='description' value={categoriesList.description} onChange={handleChange} /></> : <>
                                                <Label>{categoriesList.description}</Label>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="businessdata-label">
                                        <Label>
                                            Is Reviewed
                                        </Label>
                                    </div>
                                    <div className="userdata-input">
                                        <label className="switch small-switch">
                                            {
                                                showInput ? <>
                                                    <input type="checkbox" className="form-check-input" role="switch" name='is_reviewed' checked={categoriesList.is_reviewed ? true : false} onClick={handleClick} />
                                                    <span className="slider round slim"></span></> :
                                                    <>
                                                        <Label>{categoriesList.is_reviewed ? "Yes" : "No"}</Label>
                                                    </>
                                            }
                                        </label>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CategoryImg